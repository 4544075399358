import {ContextId} from 'react-beautiful-dnd'
import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {generateImageUrl} from '../../../../../../_metronic/helpers/generate-image-url'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {SingleFileUploadResult} from '../../../../uploads/interfaces/single-file-upload-result'
import {Field} from '../../properties-management/interfaces/field-result.interface'
import {
  CompanyChannelPropertiesData,
  CompanyChannelPropertiesResultInterface,
} from '../interfaces/company-channel-properties-result.interface'
import {CompanyChannelPropertiesSingleResultInterface} from '../interfaces/company-channel-properties-single-result.interface'
import {GenerateQrCodeResultInterface} from '../interfaces/generate-qr-code-result.interface'
import {WhatsappChatResult} from '../interfaces/wa-chat-result.interface'
import {
  DeleteCompanyChannelProperties,
  GetAllCompanyChannelProperties,
  GetAllCompanyChannelPropertiesParam,
  SubmitCompanyChannelProperties,
  UpdateCompanyChannelProperties,
} from '../providers/company-channel-properties.provider'
import {
  GenerateQRCodeWhatsapp,
  SendMessageButtonWhatsapp,
  SendMessageListWhatsapp,
  SendMessageMediaWhatsapp,
  SendMessageWhatsapp,
} from '../providers/whatsapp.provider'

export interface WhatsappChannelState {
  omniChannelCase?: string
  setOmniChannelCase?: (omniChannelCase: string) => void
  whatsappLoading: boolean
  getWhatsapps: (params: GetAllCompanyChannelPropertiesParam) => void
  whatsappItems: CompanyChannelPropertiesData[]
  whatsappSelectDatas: SelectData[]
  whatsappModel?: CompanyChannelPropertiesResultInterface
  onDelete: (id: string, params: GetAllCompanyChannelPropertiesParam) => void
  generateQrCode: (number: string) => void
  qrCodeModel?: GenerateQrCodeResultInterface
  openQrCodeModal: boolean
  setOpenQrCodeModal: (value: boolean) => void
  id?: string
  setId: (id: string) => void
  tableId?: string
  setTableId?: (tableId: string) => void
  whatsappChats: WhatsappChatResult[]
  setWhatsappChats: (value: WhatsappChatResult) => void
  sendWhatsappMessage: (
    sender: string,
    number: string,
    message: string,
    resultSingleFile: SingleFileUploadResult
  ) => void
  setCompanyChannelPropertyId: (id: string) => void
  getCompanyChannelPropertyId: () => void
  companyChannelPropertyId?: string
  selectedWhatsappNumber?: CompanyChannelPropertiesData
  setSelectedWhatsappNumber?: (item: CompanyChannelPropertiesData) => void
  fileUrl?: string
  setFileUrl?: (fileUrl: string) => void
  messageButtons?: string[]
  messageLists?: string[]
  setMessageButtons?: (buttons: string[]) => void
  setMessageLists?: (lists: string[]) => void
  addMessageButtonOrList?: () => void
  setButtonOrListsMessage?: (buttonOrListMessage?: 'button' | 'list') => void
  buttonOrListMessage?: 'button' | 'list'
  footer?: string
  setFooter?: (footer: string) => void
  title?: string
  setTitle?: (title: string) => void
  count?: number
  setCount?: (count: number) => void
}

export const useWhatsappChannelStore = create<WhatsappChannelState>((set, get) => ({
  setOmniChannelCase: (omniChannelCase) => {
    set({omniChannelCase: omniChannelCase})
  },
  omniChannelCase: undefined,
  whatsappLoading: false,
  whatsappItems: [],
  whatsappSelectDatas: [],
  whatsappModel: undefined,
  qrCodeModel: undefined,
  openQrCodeModal: false,
  whatsappChats: [],
  companyChannelPropertyId: undefined,
  setSelectedWhatsappNumber: (item: CompanyChannelPropertiesData) => {
    set({selectedWhatsappNumber: item})
  },
  setCompanyChannelPropertyId: (id: string) => {
    localStorage.setItem('companyChannelPropertyId', id)
    set({companyChannelPropertyId: id})
  },
  getCompanyChannelPropertyId: () => {
    const id = localStorage.getItem('companyChannelPropertyId')
    set({companyChannelPropertyId: id})
  },
  setWhatsappChats: (value: WhatsappChatResult) => {
    const chats = [...get().whatsappChats]
    chats.push(value)
    set({whatsappChats: chats})
  },
  setOpenQrCodeModal: (value: boolean) => {
    set({openQrCodeModal: value})
  },
  setId: (id: string) => {
    set({id: id})
  },
  setTableId: (tableId: string) => {
    set({tableId: tableId})
  },
  tableId: undefined,
  getWhatsapps: async (params: GetAllCompanyChannelPropertiesParam) => {
    set({whatsappLoading: true})
    const response = await GetAllCompanyChannelProperties(params)
    set({whatsappModel: response})
    if (response) {
      if (response.status) {
        if (response.data.items.length > 0) {
          set({whatsappItems: response.data.items})

          const selectDatas: SelectData[] = []
          for (const item of response.data.items) {
            selectDatas.push({
              label: item.account_id,
              value: item.id,
            })
          }

          set({whatsappSelectDatas: selectDatas})
        } else {
          set({whatsappItems: [], whatsappSelectDatas: []})
        }
      } else {
        set({whatsappItems: [], whatsappSelectDatas: []})
      }
    } else {
      set({whatsappItems: [], whatsappSelectDatas: []})
    }
    set({whatsappLoading: false})
  },
  onDelete: async (id: string, params: GetAllCompanyChannelPropertiesParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({whatsappLoading: true})
      const response = await DeleteCompanyChannelProperties(id)
      if (response.status) {
        toast.success(response.message)
        get().getWhatsapps(params)
      } else {
        toast.error(response.message)
      }
    }
  },
  generateQrCode: async (number: string) => {
    set({openQrCodeModal: true})
    const response = await GenerateQRCodeWhatsapp(number)
    set({qrCodeModel: response})
  },
  sendWhatsappMessage: async (
    sender: string,
    number: string,
    message: string,
    resultSingleFile: SingleFileUploadResult
  ) => {
    let messageType = 'text'

    if (get().messageButtons.length > 0) {
      messageType = 'button'
    } else if (get().messageLists.length > 0) {
      messageType = 'list'
    } else if (
      get().messageButtons.length === 0 &&
      get().messageLists.length === 0 &&
      resultSingleFile !== undefined
    ) {
      messageType = 'media'
    } else {
      messageType = 'text'
    }

    let fileType = ''
    if (resultSingleFile !== undefined) {
      if (resultSingleFile?.data?.mimetype?.includes('image')) {
        fileType = 'image'
      } else if (resultSingleFile?.data?.mimetype?.includes('pdf')) {
        fileType = 'pdf'
      } else if (
        resultSingleFile?.data?.mimetype?.includes(
          'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        )
      ) {
        fileType = 'pptx'
      } else if (resultSingleFile?.data?.mimetype?.includes('application/vnd.ms-powerpoint')) {
        fileType = 'ppt'
      } else if (resultSingleFile?.data?.mimetype?.includes('video')) {
        fileType = 'video'
      } else if (resultSingleFile?.data?.mimetype?.includes('application/msword')) {
        fileType = 'doc'
      } else if (
        resultSingleFile?.data?.mimetype?.includes(
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        )
      ) {
        fileType = 'docx'
      } else if (resultSingleFile?.data?.mimetype?.includes('application/excel')) {
        fileType = 'xls'
      } else if (
        resultSingleFile?.data?.mimetype?.includes(
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      ) {
        fileType = 'xlsx'
      } else if (resultSingleFile?.data?.mimetype?.includes('application/vnd.ms-excel')) {
        fileType = 'xls'
      }
    }

    if (messageType === 'text') {
      await SendMessageWhatsapp(sender, number, message)
    } else if (messageType === 'media') {
      await SendMessageMediaWhatsapp(
        sender,
        number,
        fileType,
        generateImageUrl(resultSingleFile?.data?.id),
        message,
        message
      )
    } else if (messageType === 'button') {
      if (resultSingleFile !== undefined) {
        await SendMessageButtonWhatsapp(
          sender,
          number,
          fileType,
          generateImageUrl(resultSingleFile?.data?.id),
          message,
          get().messageButtons,
          get().footer
        )
      } else {
        await SendMessageButtonWhatsapp(
          sender,
          number,
          '',
          '',
          message,
          get().messageButtons,
          get().footer
        )
      }
    } else if (messageType === 'list') {
      if (resultSingleFile !== undefined) {
        await SendMessageListWhatsapp({
          sender: sender,
          number: number,
          lists: get().messageLists,
          message: message,
          footer: get().footer,
          name: 'Click Me!',
          title: get().title,
          url: generateImageUrl(resultSingleFile?.data?.id),
          type: fileType,
        })
      } else {
        await SendMessageListWhatsapp({
          sender: sender,
          number: number,
          lists: get().messageLists,
          message: message,
          footer: get().footer,
          name: 'Click Me!',
          title: get().title,
          url: '',
          type: '',
        })
      }
    }

    set({
      footer: undefined,
      title: undefined,
      messageButtons: [],
      messageLists: [],
    })
  },
  fileUrl: undefined,
  messageButtons: [],
  messageLists: [],
  setMessageButtons: (buttons: string[]) => {
    set({messageButtons: buttons})
  },
  setMessageLists: (lists: string[]) => {
    set({messageLists: lists})
  },
  setButtonOrListsMessage: (buttonOrListsMessage: 'button' | 'list') => {
    if (buttonOrListsMessage === 'button') {
      // clear list properties
      set({
        messageLists: [],
      })
    } else if (buttonOrListsMessage === 'list') {
      // clear button properties
      set({
        messageButtons: [],
      })
    }
    set({buttonOrListMessage: buttonOrListsMessage})
  },
  addMessageButtonOrList: () => {
    const buttonOrList = get().buttonOrListMessage
    if (buttonOrList === 'button') {
      const buttons = [...get().messageButtons]
      buttons.push('')
      set({messageButtons: buttons})
    } else {
      const lists = [...get().messageLists]
      lists.push('')
      set({messageLists: lists})
    }
  },
  footer: undefined,
  setFooter: (footer: string) => {
    set({footer: footer})
  },
  title: undefined,
  setTitle: (title: string) => {
    set({title: title})
  },
  count: 0,
  setCount: (count: number) => {
    console.log('dsadsa_', count)
    set({count: count})
  },
}))

export interface WhatsappChannelFormState {
  field?: CompanyChannelPropertiesData
  setField?: (item: CompanyChannelPropertiesData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam: FormProps
  reset: () => void
  selectDatas: SelectData[] // if type is select
  setSelectDatas: (index: number, value: string) => void // if type is select
  addSelectDatas: () => void
  deleteSelectDatas: (index: number) => void
  generateSelectDatas: (items: SelectData[]) => void
  onSubmit: (companyChannelModelId: string) => void
  formLoading?: boolean
  submitDone?: boolean
  updateCompanyChannelProperty?: (
    id: string,
    companyChannelModelId: string,
    accountId: string,
    status: boolean
  ) => void
}

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useWhatsappCHannelFormStore = create<WhatsappChannelFormState>((set, get) => ({
  field: undefined,
  setField: (item: Field) => {
    set({field: item})
  },
  formParam: undefined,
  selectDatas: [],
  generateSelectDatas: (items: SelectData[]) => {
    set({selectDatas: items})
  },
  deleteSelectDatas: (index: number) => {
    const sd = [...get().selectDatas]
    sd.splice(index, 1)
    set({selectDatas: sd})
  },
  setSelectDatas: (index: number, value: string) => {
    const sd = [...get().selectDatas]
    sd[index] = {
      value: value,
      label: value,
    }
    set({selectDatas: sd})
  },
  addSelectDatas: () => {
    const sd = [...get().selectDatas]
    sd.push({
      value: '',
      label: '',
    })
    set({selectDatas: sd})
  },
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    let forms: FormInput[] = []
    forms = [
      {
        id: 'account_id',
        title: 'Account ID',
        placeholder: 'Account ID...',
        type: 'text',
        name: 'account_id',
        value: get().field?.account_id ?? [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms, formParam: formParam})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (companyChannelModelId: string) => {
    set({formLoading: true, submitDone: false})

    let formData = {}
    for (const v of get().forms) {
      formData = {
        ...formData,
        [v.name]: generateFormLogic(v.type, v?.value ?? ''),
      }
    }

    formData = {
      ...formData,
      company_channel_id: companyChannelModelId,
      is_connected: false,
    }

    let res: CompanyChannelPropertiesSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE)
      res = await SubmitCompanyChannelProperties(formData)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateCompanyChannelProperties(get().formParam?.id, formData)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  updateCompanyChannelProperty: async (
    id: string,
    companyChannelModelId: string,
    accountId: string,
    status: boolean
  ) => {
    set({submitDone: false, formLoading: true})
    const formData = {
      company_channel_id: companyChannelModelId,
      is_connected: status,
      account_id: accountId,
    }

    const res = await UpdateCompanyChannelProperties(id, formData)
    if (res.status) {
      // toast.success(res.message);
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      // toast.error(res.message);
      set({submitDone: false, formLoading: false})
      return
    }
  },
}))
