import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdmin = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='Marketplace'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
      >
        
        <SidebarMenuItem
          to='/apps/program/banner-management/banner'
          title='Banner Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/marketplace/merchant-management/merchants'
          title='Merchant Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/marketplace/product-category-management/categorys'
          title='Product Category Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/marketplace/product-management/products'
          title='Product Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/marketplace/transaction-management/transactions'
          title='Transaction Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/marketplace/delivery-management/deliverys'
          title='Delivery Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainAdmin
