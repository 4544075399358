import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {
  MessageTemplateData,
  MessageTemplateResultInterface,
} from '../interfaces/message-template-result.interface'
import {MessageTemplateSingleResultInterface} from '../interfaces/message-template-single-result.interface'
import {
  GetAllMessageTemplateParam,
  GetAllMessageTemplate,
  DeleteMessageTemplate,
  SubmitMessageTemplate,
  UpdateMessageTemplate,
} from '../providers/message-template.provider'

interface MessageTemplateState {
  messageTemplateLoading: boolean
  messageTemplateItems: MessageTemplateData[]
  messageTemplateSelectDatas: SelectData[]
  messageTemplateModel?: MessageTemplateResultInterface
  getMessageTemplates: (params: GetAllMessageTemplateParam) => void
  onDelete: (id: string, params: GetAllMessageTemplateParam) => void
}

export const useMessageTemplateStore = create<MessageTemplateState>((set, get) => ({
  messageTemplateLoading: false,
  messageTemplateItems: [],
  messageTemplateSelectDatas: [],
  messageTemplateModel: undefined,
  getMessageTemplates: async (params: GetAllMessageTemplateParam) => {
    set({messageTemplateLoading: true})
    const response = await GetAllMessageTemplate(params)
    set({messageTemplateModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: `${item.template}`,
            value: item.id,
          })
        }
        set({
          messageTemplateItems: response.data.items,
          messageTemplateSelectDatas: transformDatas,
        })
      } else {
        set({
          messageTemplateItems: [],
          messageTemplateSelectDatas: [],
        })
      }
    } else {
      set({
        messageTemplateItems: [],
        messageTemplateSelectDatas: [],
      })
    }
    set({messageTemplateLoading: false})
  },
  onDelete: async (id: string, params: GetAllMessageTemplateParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({messageTemplateLoading: true})
      const response = await DeleteMessageTemplate(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getMessageTemplates(params)
      set({messageTemplateLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface MessageTemplateFormState {
  field?: MessageTemplateData
  setField?: (item: MessageTemplateData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useMessageTemplateFormStore = create<MessageTemplateFormState>((set, get) => ({
  field: undefined,
  setField: (item: MessageTemplateData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'template_name',
        title: 'Template Name',
        placeholder: 'Template Name...',
        type: 'text',
        name: 'template_name',
        value: get().field?.template_name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'template',
        title: 'Template',
        placeholder: 'Template...',
        type: 'text-area',
        name: 'template',
        value: get().field?.template ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: MessageTemplateSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitMessageTemplate(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateMessageTemplate(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
