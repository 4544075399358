import Axios, {AxiosInstance} from 'axios'
import {NavigateFunction} from 'react-router-dom'
import {AuthModel, AUTH_LOCAL_STORAGE_KEY} from '../modules/auth'
// import { AuthState } from '../modules/auth/states/auth.state';

export const Api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const ApiAuth = (token: string) =>
  Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

export const ApiCrm = Axios.create({
  baseURL: process.env.REACT_APP_API_CRM_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const ApiProgram = Axios.create({
  baseURL: process.env.REACT_APP_API_PROGRAM_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const ApiMarketplace = Axios.create({
  baseURL: process.env.REACT_APP_API_MARKETPLACE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const ApiWhatsappGateway = Axios.create({
  baseURL: process.env.REACT_APP_API_WHATSAPP_GATEWAY,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const ApiUploadFile = (): AxiosInstance => {
  let apiKey = process.env?.REACT_APP_STORAGE_KEY
  return Axios.create({
    baseURL: process.env.REACT_APP_STORAGE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
      'storage-api-key': apiKey,
    },
  })
}

export const ApiDetailFile = (): AxiosInstance => {
  let apiKey = process.env?.REACT_APP_STORAGE_KEY
  return Axios.create({
    baseURL: process.env.REACT_APP_STORAGE_URL_DETAIL,
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
      'storage-api-key': apiKey,
    },
  })
}

export const AxioxCrmInterceptorSetup = (navigate: NavigateFunction, callback: () => void) => {
  // authStore
  // const { logout } = useAuthStore;

  // interceptor for request
  ApiCrm.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel

    // add token to header
    config.headers['Authorization'] = `Bearer ${auth?.data?.accessToken}`

    return config
  })

  // interceptor for response
  ApiCrm.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error.response.status)
      if (error.request.status === 401) {
        localStorage.clear()
        callback()
        // navigate('/auth');
      }
      return Promise.reject(error)
    }
  )
}

export const AxiosInterceptorSetup = (navigate: NavigateFunction, callback: () => void) => {
  // authStore
  // const { logout } = useAuthStore;

  // interceptor for request
  Api.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel

    // add token to header
    config.headers['Authorization'] = `Bearer ${auth?.data?.accessToken}`

    return config
  })

  // interceptor for response
  Api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error.response.status)
      if (error.request.status === 401) {
        localStorage.clear()
        callback()
        // navigate('/auth');
      }
      return Promise.reject(error)
    }
  )
}

export const AxiosProgramInterceptorSetup = (navigate: NavigateFunction, callback: () => void) => {
  // authStore
  // const { logout } = useAuthStore;

  // interceptor for request
  ApiProgram.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel

    // add token to header
    config.headers['Authorization'] = `Bearer ${auth?.data?.accessToken}`

    return config
  })

  // interceptor for response
  ApiProgram.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error.response.status)
      if (error.request.status === 401) {
        localStorage.clear()
        callback()
        // navigate('/auth');
      }
      return Promise.reject(error)
    }
  )
}

export const AxioxMarketplaceInterceptorSetup = (
  navigate: NavigateFunction,
  callback: () => void
) => {
  // authStore
  // const { logout } = useAuthStore;

  // interceptor for request
  ApiMarketplace.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel

    // add token to header
    config.headers['Authorization'] = `Bearer ${auth?.data?.accessToken}`

    return config
  })

  // interceptor for response
  ApiMarketplace.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error.response.status)
      if (error.request.status === 401) {
        localStorage.clear()
        callback()
        // navigate('/auth');
      }
      return Promise.reject(error)
    }
  )
}
